import React, { Component } from 'react';
import {FormattedMessage} from "react-intl";
import { Modal } from "react-bootstrap";
import { SubscriptionType, updateSubscriptionPreferences, getTextAlignment, getHeaderJustify } from "../utils";
import ghost from '../ghostlogo.png'

export class SecurityAlert extends Component {
    constructor(props) {
      super(props);
  
      this.state = {
        confirmed: false,
        preferences: {},
        title: undefined,
        status: undefined,
        textAlignment: getTextAlignment(props.direction),
        headerJustify: getHeaderJustify(props.direction)
      };
    }
  
    handleConfirm = () => {
      updateSubscriptionPreferences(SubscriptionType.SMS, this.state.preferences, this.props.token, true,
        (body) => {
          this.setState({
              ...this.state,
              confirmed: true,
              title: (<p className="sms-title">{body['phone_number']}</p>),
              status: (<p className="greet-status"><FormattedMessage id='sms_optout.success'/></p>)
            });
        }, () => {
          this.setState({
            ...this.state,
            confirmed: true,
            title: undefined,
            status: (<p className="opt-status"><FormattedMessage id='opt_status.error'/></p>)
          });
        });
    }

    handleReject = () => {
      // Workaround to make the browser think we opened our current window
      window.open("about:blank", "_self");
      window.close();
    };

    componentDidMount() {
      if (this.props.category !== undefined
          && this.props.category != null
          && this.props.category !== "") {
        this.state.preferences[this.props.category] = "UNSUBSCRIBE";
        this.state.textAlignment = getTextAlignment(this.props.direction);
        this.state.headerJustify = getHeaderJustify(this.props.direction);
      }
    }
  
    render() {
      return (
        <div dir={this.props.direction} className="container main-container">
          <img src={ghost} width="61" height="61" alt=""/>
          <Modal
            show={!this.state.confirmed}
            backdrop="static"
            keyboard={false}
            className="conf-dialog"
          >
            <Modal.Header style={this.state.headerJustify}>
              <Modal.Title>Snapchat</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p style={this.state.textAlignment}>
                <FormattedMessage id='sms_optout.security_alert.confirm'/>
              </p>
              <p className="conf-disclaimer"  style={this.state.textAlignment}>
                <FormattedMessage id='sms_optout.security_alert.confirm.disclaimer'/>
              </p>
            </Modal.Body>
            <Modal.Footer className={"conf-footer"}>
              <div className="conf-buttons">
                <button className={"btn btn-secondary"} onClick={this.handleReject}>
                  <FormattedMessage id='sms_optout.confirm.negative'/>
                </button>
                <button className={"btn conf-affirm-btn"} onClick={this.handleConfirm}>
                  <FormattedMessage id='sms_optout.confirm.affirmative'/>
                </button>
              </div>
              <div className="conf-contact-root">
                <p className="conf-contact-link"><a href="https://snap.com">snap.com</a></p>
                <div className="conf-contact-address" style={this.state.textAlignment}>
                  <p><FormattedMessage id='sms_optout.confirm.address.us'/></p>
                  <p><FormattedMessage id='sms_optout.confirm.address.uk'/></p>
                </div>
              </div>
            </Modal.Footer>
          </Modal>
          {this.state.confirmed && this.state.title}
          {this.state.confirmed && this.state.status}
        </div>
      )
    }
  }