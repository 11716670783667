import React, { Component } from 'react';
import {FormattedMessage} from "react-intl";
import { PreferencesCard } from "../components/PreferencesCard";
import { getTextAlignment, getMaskedEmail, SubscriptionType, updateSubscriptionPreferences } from "../utils";
import ghost from '../ghostlogo.png'

// NEVER CHANGE THESE IDS!!!
// FIXME: I don't like how out of sync these can get...
const CATEGORIES = [
    {
      id: 'creative_tools',
      name: <FormattedMessage id='categories.creative_tools.name'/>,
      description: <FormattedMessage id='categories.creative_tools.description'/>
    },
    {
      id: 'product_updates',
      name: <FormattedMessage id='categories.product_updates.name'/>,
      description: <FormattedMessage id='categories.product_updates.description'/>
    },
    {
      id: 'discover_updates',
      name: <FormattedMessage id='categories.discover_updates.name'/>,
      description: <FormattedMessage id='categories.discover_updates.description'/>
    },
    {
      id: 'user_surveys',
      name: <FormattedMessage id='categories.user_surveys.name'/>,
      description: <FormattedMessage id='categories.user_surveys.description'/>
    },
    {
      id: 'lens_studio',
      name: <FormattedMessage id='categories.lens_studio.name'/>,
      description: <FormattedMessage id='categories.lens_studio.description'/>
    },
    {
      id: 'developer_platform',
      name: <FormattedMessage id='categories.developer_platform.name'/>,
      description: <FormattedMessage id='categories.developer_platform.description'/>
    },
    {
      id: 'friending',
      name: <FormattedMessage id='categories.friending.name'/>,
      description: <FormattedMessage id='categories.friending.description'/>
    }
    // This is confusing when selected multiple categories with some categories not selected.
    // After discussion. We decided to disable this for now.
    // {
    //   id: 'all',
    //   name: <FormattedMessage id='categories.all.name'/>,
    //   description: <FormattedMessage id='categories.all.description'/>
    // }
  ];

export class Email extends Component {
    constructor(props) {
      super(props);

      this.state = {
        email_title: undefined,
        status: (<p className="opt-status"><FormattedMessage id='opt_status.updating'/></p>)
      };
    }

    componentDidMount() {
      const preferences = {};
      if (this.props.category !== undefined
          && this.props.category != null
          && this.props.category !== "") {
        preferences[this.props.category] = "UNSUBSCRIBE";
      }

      updateSubscriptionPreferences(SubscriptionType.EMAIL, preferences, this.props.token, true,
          (body) => {
            this.setState({
              preferences_card: (<PreferencesCard token={this.props.token}
                                                  subscriptionType={SubscriptionType.EMAIL}
                                                  alignment={getTextAlignment(this.props.direction)}
                                                  categories={CATEGORIES}
                                                  preferences={('subscriptions' in body) ? body['subscriptions'] : {}}/>),
              email_title: (<p className="email-title">{getMaskedEmail(body['email_address'])}</p>),
              status: (<p className="greet-status"><FormattedMessage id='opt_status.greet.new'/></p>)
            });
          }, () => {
            this.setState({
              email_title: undefined,
              status: (<p className="opt-status"><FormattedMessage id='opt_status.error'/></p>)
            });
          });
    }

    render() {
      return (
        <div dir={this.props.direction} className="container main-container">
          <img src={ghost} width="61" height="61" alt=""/>
          {this.state.status}
          {this.state.email_title}
          <p className="opt-sub"><FormattedMessage id='opt_description_new'/></p>
          {this.state.preferences_card}
        </div>
      )
    }
  }
