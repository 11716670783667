import React from 'react';
import './App.css';
import './bootstrap.min.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import { Email, Sms, SecurityAlert } from "./views";

const App = (appProps) => {
  // To have a short opt-out link for security alerts, we omit the campaign category Id param when generating the link on the server
  // and will add it back behind the scene when the user taps the link to opt-out. This category Id must match the one used on the
  // server. https://github.sc-corp.net/Snapchat/email-infra/blob/master/email-mesh/sms-subscription-management/service-stubs/src/main/java/com/snapchat/subscription/sms/service/Utils.java#L86
  let securityAlertCategory = "I";
  return (
    <Router>
      <div>
        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Switch>
          <Route
            path="/alert*"
            render={(props) => (
              <SecurityAlert {...appProps} category={securityAlertCategory} />
            )}>
          </Route>
          <Route
            path="/sms*"
            render={(props) => (
              <Sms {...appProps} />
            )}>
          </Route>
          {/* The standard email unsubscribe path is '/manage' */}
          <Route
            path="/"
            render={(props) => (
              <Email {...appProps} />
            )}>
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
