import axios from "axios";
import {getUA} from "react-device-detect";
import { SubscriptionType } from "./constants";

export function updateSubscriptionPreferences(subscriptionType, subscriptionPreferences, token, incremental, onSuccess, onError) {
  const data = subscriptionType === SubscriptionType.SMS ?
  {
    token: token,
    subscriptions: subscriptionPreferences
  }
  :
  {
    email_id: token,
    incremental: incremental,
    subscriptions: subscriptionPreferences,
    user_agent: getUA
  };
  
    console.debug("data: " + JSON.stringify(data));
    const endpointPath = `${subscriptionType === SubscriptionType.SMS ? "sms/" : ""}set_preferences`
    axios.post(
      process.env.REACT_APP_SERVER_URL + endpointPath,
      data,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
      .then(response => {
        if (response.status === 200) {
          console.debug(JSON.stringify(response.data));
          onSuccess(response.data);
        } else {
          onError(response.statusCode);
        }
      })
      .catch(error => {
        console.error(error);
        if (error !== null && error !== undefined) {
          onError(error);
        }
      });
  }