import React, { Component } from 'react';

export class Preference extends Component {
    constructor(props) {
      super(props);
      this.handleChange = this.handleChange.bind(this);
      this.state = {
        disabled: !this.props.checked
      }
    }

    handleChange() {
      this.props.onPreferenceChanged({
        category_id: this.props.id,
        status: !this.props.checked ? 'SUBSCRIBE' : 'UNSUBSCRIBE'
      });
      if(this.props.checked) {
        this.setState({disabled: true})
      } else {
        this.setState({disabled: false})
      }
    }

    render() {
      let disabled = ''
      if (this.props.disabled || this.state.disabled) disabled = ' disabled'
      let checked = false
      if (!this.props.disabled && this.props.checked) checked = true
      return (
        <div className={"preference-card-option" + disabled}>
          <div className={"option-detail"}>
            <input className={"checkbox" + disabled} type="checkbox" style={{width: 20, height: 20}} checked={checked} onChange={this.handleChange}/>
          </div>
          <div>
            <p className={"option-header" + disabled} style={this.props.alignment}><strong>{this.props.name}</strong></p>
            <p className={"option-description" + disabled} style={this.props.alignment}>{this.props.description}</p>
          </div>

        </div>
      );
    }
  }
