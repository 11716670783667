import React, { Component } from 'react';
import {FormattedMessage} from "react-intl";
import { Preference } from "./Preference";
import { updateSubscriptionPreferences } from "../utils/preferenceUtils";

export class PreferencesCard extends Component {
    constructor(props) {
      super(props);

      this.handlePreferenceChanged = this.handlePreferenceChanged.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.handlePreferencesSaved = this.handlePreferencesSaved.bind(this);
      this.handlePreferencesSaveError = this.handlePreferencesSaveError.bind(this);
      this.handleOKToSubmitAgain = this.handleOKToSubmitAgain.bind(this);

      this.state = {
        preferences : props.preferences,
        submit_state: 'READY'
      }
    }

    handlePreferenceChanged(e) {
      const category_id = e.category_id;
      const status = e.status;

      console.debug("category_id: " + category_id);
      console.debug("status: " + status);
      let localPreferences = this.state.preferences;
      localPreferences[category_id] = status;
      this.setState({
          preferences: localPreferences
        }
      );
    }

    handleSubmit(e) {
      this.setState({
        submit_state: 'SAVING'
      });

      updateSubscriptionPreferences(
          this.props.subscriptionType,
          this.state.preferences,
          this.props.token,
          false,
          () => this.handlePreferencesSaved(), // We drop the preferences returned by the server, because things may have changed.
          () => this.handlePreferencesSaveError());
    }

    handlePreferencesSaved() {
      this.setState({
        submit_state: 'SAVED'
      });

      setTimeout(
        () => this.handleOKToSubmitAgain(),
        2000
      );
    }

    handlePreferencesSaveError() {
      this.setState({
        submit_state: 'ERROR'
      });

      // TODO: Store old preferences state, and go back to it?
      setTimeout(
        () => this.handleOKToSubmitAgain(),
        2000
      );
    }

    handleOKToSubmitAgain() {
      this.setState({
        submit_state: 'READY'
      });
    }

    render() {
      const categories = this.props.categories.map((category) => {
        return <Preference
          alignment={this.props.alignment}
          name={category.name}
          id={category.id}
          description={category.description}
          key={category.id}
          checked={[undefined, '', 'SUBSCRIBE'].indexOf(this.state.preferences[category.id]) > -1}
          onPreferenceChanged={this.handlePreferenceChanged}
          disabled={this.state.preferences.all === 'UNSUBSCRIBE' && category.id !== 'all'}
        />
      });

      let submitButton;
      switch(this.state.submit_state) {
        case 'READY': submitButton = (
            <button className={"btn btn-primary save-pref-btn " + this.state.submit_state} value="Save Preferences" onClick={this.handleSubmit}>
              <FormattedMessage id='preferences.button.save'/>
            </button>
          );
          break;
        case 'SAVING': submitButton = (
            <button className={"btn btn-primary save-pref-btn " + this.state.submit_state} value="Saving Preferences..." disabled>
              <FormattedMessage id='preferences.button.saving'/>
            </button>
          );
          break;
        case 'SAVED': submitButton = (
            <button className={"save-pref-btn btn btn-primary " + this.state.submit_state} value="Preferences Saved!" disabled>
              <FormattedMessage id='preferences.button.saved'/>
            </button>
          );
          break;
        case 'ERROR': submitButton = (
            <button className={"btn btn-primary save-pref-btn " + this.state.submit_state} value="Error! Try again!" disabled>
              <FormattedMessage id='preferences.button.error'/>
            </button>
          );
          break;
        default:
          break;
      }

      return (
        <div className="card preference-card">
          <div className="card-body">
            {categories}
            <div className="separator"/>
            {submitButton}
          </div>
        </div>
      )
    }
  }
