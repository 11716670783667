import Cookies from 'js-cookie';
import Request from "request";

function maskString(str) {
    if (str == null || str.length < 3)
        return str;

    return str[0] + "*".repeat(str.length - 2) + str.slice(-1);
}

function validateLanguageSelection(default_language, available_languages, choice) {
    if (choice === undefined || choice == null) {
        return default_language;
    }

    if (available_languages.includes(choice)) {
        return choice;
    }

    let lang_only = choice.split("-", 1)[0];

    for(let i in available_languages) {
        if (available_languages[i].startsWith(lang_only)) {
            return available_languages[i];
        }
    }

    return default_language;
}

export function getNavigatorLanguage() {
    if (navigator === undefined || navigator == null) {
        return null;
    }

    if (navigator.language) {
        return navigator.language;
    }
    if (!!navigator.languages && !!navigator.languages[0]) {
        return navigator.languages[0];
    }
    if (navigator.userLanguage) {
        return navigator.userLanguage;
    }
    if (navigator.browserLanguage) {
        return navigator.browserLanguage;
    }

    return null;
}

export function getCookieLanguage() {
    const cookie_language = Cookies.get('x-snap-language');

    if (cookie_language === undefined || cookie_language == null) {
        return null;
    }

    return cookie_language;
}

export function setCookieLanguage(language) {
    Cookies.set('x-snap-language', language)
}

export function getLanguage(available_languages) {
    var selected_language = available_languages['default_language'];

    selected_language = validateLanguageSelection(
        selected_language, available_languages['available'], getNavigatorLanguage(available_languages));
    selected_language = validateLanguageSelection(
        selected_language, available_languages['available'], getCookieLanguage(available_languages));

    return selected_language;
}

export function getLanguageDirection(language_tag) {
    let lang_only = language_tag.split("-", 1)[0];

    switch (lang_only) {
        /*
        All RTL languages:
           - Arabic
           - Aramaic (no code)
           - Azeri (no code)
           - Divehi
           - Fula
           - Hebrew
           - Kurdish
           - N'ko (no code)
           - Persian
           - Rohingya (no code)
           - Syriac (no code)
           - Urdu
         */
        case "ar":
        case "dv":
        case "ff":
        case "he":
        case "ku":
        case "fa":
        case "ur":
            return "rtl";
        default:
            return "ltr";
    }
}

export function getTextAlignment(direction) {
        if (direction === 'rtl')
            return {'textAlign': 'right', 'direction': 'rtl'};
        else
            return {'textAlign': 'left'};
}

export function getHeaderJustify(direction) {
    if (direction === 'rtl')
        return {'justify-content': 'flex-end'};
    else
    return {'justify-content': 'flex-start'};
}

export function getMaskedEmail(email) {
    if (!email.includes("@"))
        return maskString(email);

    var email_parts = email.split("@");
    return maskString(email_parts[0]) + "@" + email_parts[1];
}

export function loadTranslations(onSuccess, onError) {
    Request({
        uri: process.env.REACT_APP_SERVER_URL + "translations/available-languages.json",
        json: true,
        timeout: 10000,
    }, (error, response, body) => {
        if (error !== null && error !== undefined) {
            console.log(error);
            onError(error);
        } else if (response.statusCode === 200) {
            let language = getLanguage(body);
            let direction = getLanguageDirection(language);

            Request({
                uri: process.env.REACT_APP_SERVER_URL + "translations/" + language + ".json",
                json: true,
                timeout: 10000,
            }, (error, response, body) => {
                if (error == null && error === undefined) {
                    console.log(error);
                    onError(error);
                } else if (response.statusCode === 200) {
                    onSuccess(body, direction);
                } else {
                    onError(response.statusCode);
                }
            });
        } else {
            onError(response.statusCode);
        }
    });
}

