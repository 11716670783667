import React, { Component } from 'react';
import {FormattedMessage} from "react-intl";
import { Modal } from "react-bootstrap";
import { SubscriptionType, updateSubscriptionPreferences, getTextAlignment, getHeaderJustify } from "../utils";
import ghost from '../ghostlogo.png'

export class Sms extends Component {
    // maps to CAMPAIGN_CODE_TO_CAMPAIGN_TYPE_MAP in ~/email-mesh/sms-subscription-management/common/src/main/java/com/snapchat/subscription/sms/Utils.java
    CategoryToConfirmationSuffixId = {
      "P": "sms_optout.success.invites_in_registration",
      "Q": "sms_optout.success.memories_link_share",
      "R": "sms_optout.success.friend_add_notification",
      "S": "sms_optout.success.public_link_spotlight_snap",
      "T": "sms_optout.success.public_link_discover_profile",
      "U": "sms_optout.success.public_link_lens",
      "V": "sms_optout.success.public_link_user_profile",
      "W": "sms_optout.success.public_link_discover_episode",
      "X": "sms_optout.success.public_link_map_story",
      "Y": "sms_optout.success.public_link_public_profile",
//    "Z": "sms_optout.success.public_link_public_profile_snap",
      "a": "sms_optout.success.snap_anyone_invite",
    };
    CategoryToDisclaimerId = {
      "P": "sms_optout.initial.invites_in_registration",
      "Q": "sms_optout.initial.memories_link_share",
      "R": "sms_optout.initial.friend_add_notification",
      "S": "sms_optout.initial.public_link_spotlight_snap",
      "T": "sms_optout.initial.public_link_discover_profile",
      "U": "sms_optout.initial.public_link_lens",
      "V": "sms_optout.initial.public_link_user_profile",
      "W": "sms_optout.initial.public_link_discover_episode",
      "X": "sms_optout.initial.public_link_map_story",
      "Y": "sms_optout.initial.public_link_public_profile",
//    "Z": "sms_optout.initial.public_link_public_profile_snap",
      "a": "sms_optout.initial.snap_anyone_invite",
    };

    constructor(props) {
      super(props);
      this.state = {
        confirmed: false,
        preferences: {},
        title: undefined,
        status: undefined,
        textAlignment: getTextAlignment(props.direction),
        headerJustify: getHeaderJustify(props.direction)
      };
    }
  
    handleConfirm = () => {
      updateSubscriptionPreferences(SubscriptionType.SMS, this.state.preferences, this.props.token, true,
        (body) => {
          var successStatus = null;
          if (this.CategoryToConfirmationSuffixId[this.props.category]) {
            successStatus = (<p className="greet-status"><FormattedMessage id='sms_optout.success'/><br/><FormattedMessage id={this.CategoryToConfirmationSuffixId[this.props.category]}/></p>)
          } else {
            successStatus = (<p className="greet-status"><FormattedMessage id='sms_optout.success'/></p>)
          }

          this.setState({
              ...this.state,
              confirmed: true,
              title: (<p className="sms-title">{body['phone_number']}</p>),
              status: successStatus
            });
        }, () => {
          this.setState({
            ...this.state,
            confirmed: true,
            title: undefined,
            status: (<p className="opt-status"><FormattedMessage id='opt_status.error'/></p>)
          });
        });
    }

    handleReject = () => {
      // Workaround to make the browser think we opened our current window
      window.open("about:blank", "_self");
      window.close();
    };

    componentDidMount() {
      if (this.props.category !== undefined
          && this.props.category != null
          && this.props.category !== "") {
        this.state.preferences[this.props.category] = "UNSUBSCRIBE";
        this.state.textAlignment = getTextAlignment(this.props.direction);
        this.state.headerJustify = getHeaderJustify(this.props.direction);
      }
    }
  
    render() {
      var confirmMessage = null;
      if (this.CategoryToConfirmationSuffixId[this.props.category]) {
        confirmMessage = (<p style={this.state.textAlignment}><FormattedMessage id={this.CategoryToDisclaimerId[this.props.category]}/></p>)
      } else {
        confirmMessage = (<p style={this.state.textAlignment}><FormattedMessage id='sms_optout.confirm'/></p>)
      }

      return (
        <div dir={this.props.direction} className="container main-container">
          <img src={ghost} width="61" height="61" alt=""/>
          <Modal
            show={!this.state.confirmed}
            backdrop="static"
            keyboard={false}
            className="conf-dialog"
          >
            <Modal.Header style={this.state.headerJustify}>
              <Modal.Title>Snapchat</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {confirmMessage}
              <p className="conf-disclaimer" style={this.state.textAlignment}>
                <FormattedMessage id='sms_optout.confirm.disclaimer'/>
              </p>
              <p className="conf-disclaimer" style={this.state.textAlignment}>
                <FormattedMessage 
                  id='sms_optout.confirm.support'
                  values={{
                    a: chunks => (
                      <a href="https://support.snapchat.com/i-need-help">
                        {chunks}
                      </a>
                    ),
                  }}
                />
              </p>
            </Modal.Body>
            <Modal.Footer className={"conf-footer"}>
              <div className="conf-buttons">
                <button className={"btn btn-secondary"} onClick={this.handleReject}>
                  <FormattedMessage id='sms_optout.confirm.negative'/>
                </button>
                <button className={"btn conf-affirm-btn"} onClick={this.handleConfirm}>
                  <FormattedMessage id='sms_optout.confirm.affirmative'/>
                </button>
              </div>
              <div className="conf-contact-root">
                <p className="conf-contact-link"><a href="https://snap.com">snap.com</a></p>
                <div className="conf-contact-address" style={this.state.textAlignment}>
                  <p><FormattedMessage id='sms_optout.confirm.address.us'/></p>
                  <p><FormattedMessage id='sms_optout.confirm.address.uk'/></p>
                </div>
              </div>
            </Modal.Footer>
          </Modal>
          {this.state.confirmed && this.state.title}
          {this.state.confirmed && this.state.status}
        </div>
      )
    }
  }