import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import QueryString from 'query-string';
import {IntlProvider} from 'react-intl';
import { loadTranslations } from './utils'

loadTranslations((body, direction) => {
    ReactDOM.render((
        <IntlProvider locale='en' messages={body}>
            <App
                direction={direction}
                token={QueryString.parse(window.location.search)['id'] || QueryString.parse(window.location.search)['t']}
                category={QueryString.parse(window.location.search)['optout'] || QueryString.parse(window.location.search)['c']}
                incremental={QueryString.parse(window.location.search)['incremental']}
            />
        </IntlProvider>), document.getElementById('root'));
}, () => console.log("Could not load page..."));



// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
